<template>
  <div class="page">
    <el-container>
      <el-header>
        <Head :slide="slide" @logout="logout"></Head>
       
      </el-header>
      <el-main>
        <div class="">
            <ul class="bread-crumb">
                <li class="item item-1 fill" @click="$router.push('/pre/upload?id=' + id)">
                     {{$t('report.pre') + $t('report.upload')}}
                </li>
                <li class="item item-2 fill" @click="$router.push('/pre/scale?id=' + id)">
                     {{$t('report.pre') + $t('report.scale')}}
                </li>
                <li class="item item-3 fill" >
                     {{$t('report.pre') + $t('report.level')}}
                </li>
                <li class="item item-3 ">
                     {{$t('report.pre') + $t('report.measure')}}
                </li>
                <li class="item item-3 ">
                     {{$t('report.pre') + $t('report.install')}}
                </li>
                <li class="item item-4">
                     {{$t('report.pre') + $t('report.create')}}
                </li>
                
            </ul>
          
            <div class="main">
                <div class="left-side">
                   
                    <h2 class="content-title">
                        <div class="title">
                            <span>{{$t('report.step')}}</span>
                        </div>
                    </h2>
                    <ul class="step-list">
                        <li class="item active">
                            1.{{$t('report.level')}}
                        </li>
                        <li class="item">
                            2.{{$t('report.measure')}}
                        </li>
                        <li class="item">
                            3.{{$t('report.install')}}
                        </li>
                    </ul>
                      
               </div>
               <div class="canvas-box" style="position: relative">
                    <div class="container" :style="{'width': oldWidth + 'px', 'height': oldHeight + 'px', 'overflow': 'auto'}">
                        <v-stage :config="configKonva" ref="stage">
                            <v-layer ref="layer">
                                <v-group ref="frontGroup" :config="configGroup">
                                    <v-image ref="xFront" :config="stageImage"/>>
                                </v-group>
                                <v-group ref="frontLevelGroup" :config="configFrontLevelGroup">
                                    <v-line ref="lineFront" :config="configLineFront"></v-line>
                                    <v-circle ref="circleFront" :config="configCircleFront"></v-circle>
                                </v-group >
                            </v-layer>
                        </v-stage>


                    </div>
                    <Slide :slide="frontSlide" direction="front" @onChange="frontSlideChange"></Slide>
                    <Zoom class="zoom"
                    @onIncrease="onIncrease"
                    @onReset="onReset"
                    @onReduce="onReduce"
                    ></Zoom>
                </div>
                <div class="canvas-box" style="position: relative">
                    <div class="container" :style="{'width': oldWidth + 'px', 'height': oldHeight + 'px', 'overflow': 'auto'}">
                        <v-stage :config="configKonva" ref="stage2">
                            <v-layer ref="layer2">
                                <v-group ref="sideGroup" :config="configGroup">
                                    <v-image ref="xSide" :config="sideImage"/>>
                                </v-group>
                                <v-group ref="sideLevelGroup" :config="configSideLevelGroup">
                                    <v-line ref="lineSide" :config="configLineSide"></v-line>
                                    <v-circle ref="circleSide" :config="configCircleSide"></v-circle>
                                </v-group >
                            </v-layer>
                        </v-stage>
                    </div>
                    <Slide :slide="sideSlide" direction="side" @onChange="sideSlideChange"></Slide>
                    <Zoom class="zoom"
                    @onIncrease="onIncrease"
                    @onReset="onReset"
                    @onReduce="onReduce"
                    ></Zoom>
                </div>
               <div class="right-side">
                   
                    <h2 class="content-title">
                        <div class="title">
                            <span>{{$t('report.level')}}</span>
                        </div>
                    </h2>
                    <el-form
                    
                    ref="ruleForm"
                    label-width="100px"
                    class="form"
                    label-position="left"
                    >
                        <div :class="['btn-comfirm', {'btn-is-comfirm': isConfirm}]" 
                         @click="lineComfirm">{{isConfirm ? $t('report.affirm') : $t('report.confirm')}}</div>
                        <div class="btn-revocation" @click="lineRevocation">{{$t('report.revocation')}}</div>
                        <div class="btn-submit" @click="submit">{{$t('report.nextStep')}}</div>
                        <div class="text-upload" @click="lastStep">{{$t('report.lastStep')}}</div>
                    </el-form>
                      
               </div>
            </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import Zoom from '../../components/Zoom';
export default {
    components:{
        Zoom
    },
    data(){
        return{
            id: '', //病例id 
            stage: null, //画布
            layer: null, //画笔
            stage2: null, //画布2
            layer2: null, //画笔2
            frontImageObj: new Image(), //正位初始图片对象
            sideImageObj: new Image(), //侧位初始图片对象
            oldWidth: document.documentElement.clientWidth * 0.32, // 画布初始宽度
            oldHeight: document.documentElement.clientHeight * 0.75, // 画布初始高度
            widthIn: document.documentElement.clientWidth * 0.32, // 当前画布宽度
            heightIn: document.documentElement.clientHeight * 0.75, // 当前画布高度
            widthIn2: document.documentElement.clientWidth * 0.32, 
            heightIn2: document.documentElement.clientHeight * 0.75,
            xRay: null, 
            xRayS: null,
            multiple: 1,
            base: [],
            isConfirm: false,
            disabled: false,
            isUnify: false,
            slideText: '',
            slide: '',
            frontSlide: '0', // 正位患侧
            sideSlide: '0', // 侧位患侧
            levelArray: [],
        }
    },
    computed: {
        //画布配置
        configKonva(){ 
            return {
                width: this.widthIn,  
                height: this.heightIn
  			}
        },
        //正位图配置
  		stageImage () { 
         
            return {
                x: this.configKonva.width / 2 ,
                y: this.heightIn / 2,
                rotation: 0, //旋转角度
                // draggable: true, //可拖拽
                image: this.frontImageObj,
                offset: {
                    x: this.frontImageObj.width / 2,
                    y: this.frontImageObj.height / 2
                },
                
            }
  		},
        //侧位图配置
  		sideImage () { 
            return {
                x: this.configKonva.width / 2 ,
                y: this.heightIn / 2,
                rotation: 0, //旋转角度
                // draggable: true, //可拖拽
                image: this.sideImageObj,
                offset: {
                    x: this.sideImageObj.width / 2,
                    y: this.sideImageObj.height / 2
                },

            }
  		},
        //正位水平点
        configCircleFront(){
            return {
                x: this.oldWidth / 5,
                y: 0,
                radius: 10 / this.multiple,
                fill: '#208EFF',
                stroke: '#FFFFFF',
                strokeWidth: 2 / this.multiple
            }
        },
        //侧位水平点
        configCircleSide(){
            return {
                x: this.oldWidth / 5,
                y: 0,
                radius: 10  / this.multiple,
                fill: '#208EFF',
                stroke: '#FFFFFF',
                strokeWidth: 2 / this.multiple
            }
        },
        //正位水平线
        configLineFront(){
            return {
                points : [0, this.configCircleFront.y, this.configKonva.width, this.configCircleFront.y],
                stroke: '#208EFF',
                strokeWidth: 2 / this.multiple,
                lineJoin: 'round',
                dash: [5 / this.multiple, 5 / this.multiple],
                
            }
        },
        //侧位水平线
        configLineSide(){
            return {
                points : [0, this.configCircleSide.y, this.configKonva.width, this.configCircleSide.y],
                stroke: '#208EFF',
                strokeWidth: 2 / this.multiple,
                lineJoin: 'round',
                dash: [5 / this.multiple, 5 / this.multiple],
                
            }
        },
        configFrontLevelGroup(){
            let that = this;
            return {
                draggable: true,
                dragBoundFunc: function(pos) {
                    let newY = pos.y < 0 ? 0 
                    : pos.y > that.heightIn 
                    ? that.heightIn
                    : pos.y;
                    return {
                        x: this.absolutePosition().x,
                        y: newY
                    };
                }
            }
        },
        configSideLevelGroup(){
            let that = this;
            return {
                draggable: true,
                dragBoundFunc: function(pos) {
                    let newY = pos.y < 0 ? 0 
                    : pos.y > that.heightIn 
                    ? that.heightIn
                    : pos.y;
                    return {
                        x: this.absolutePosition().x,
                        y: newY
                    };
                }
            }
        },
        configGroup(){
                return {
                    // rotation: 10, //旋转角度
                   
                }
           
        },
        
  	},
 
    created(){
        if(this.$route.query.id){
		    this.id = this.$route.query.id;
            this.$api.getReport({
                before_after: 0,
                id: this.id
            }).then(res => {
                if(res.code){
                    this.slideText = res.data.case.slide_text;
                    this.slide = res.data.case.slide;
                    this.base = res.data.base;
                    this.frontSlide =  this.base[0].left_right ? JSON.parse(this.base[0].left_right).slide : this.slide;
                    this.sideSlide =  this.base[1].left_right ? JSON.parse(this.base[1].left_right).slide : this.slide;
                    this.frontImageObj.src = this.$baseUrl + this.base[0].image;
                    this.sideImageObj.src = this.$baseUrl + this.base[1].image;
                    const frontLevel = this.base[0].point ? JSON.parse(this.base[0].point) : false;
                    const sideLevel = this.base[1].point ? JSON.parse(this.base[1].point) : false;
                    if(frontLevel && sideLevel){
                        this.$refs.frontLevelGroup.getNode().y(frontLevel.y);
                        this.$refs.sideLevelGroup.getNode().y(sideLevel.y);
                    }
                    if(this.base[0].zoom ){
                        setTimeout(() => {
                            this.multiple = Number(this.base[0].zoom);
                            this.zoom();
                        },10)
                    }
                }
            })
	    }else{
		 this.$message.error(this.$t('common.errMsg2'));
		 setTimeout(res=>{
			this.$router.go(-1)
		 },500)
	    }
        //

    },
    mounted(){
        document.getElementsByClassName('right-side')[0].style.height = this.configKonva.height + 'px'; 
        document.getElementsByClassName('left-side')[0].style.height = this.configKonva.height + 'px'; 
        this.stage = this.$refs.stage.getNode();
        this.layer = this.$refs.layer.getNode();
        this.stage2 = this.$refs.stage2.getNode();
        this.layer2 = this.$refs.layer2.getNode();
        this.xRay = this.$refs.xFront.getNode();
        this.xRayS = this.$refs.xSide.getNode();
        let that = this;
        let frontLevelGroup = this.$refs.frontLevelGroup.getNode();
        let sideLevelGroup = this.$refs.sideLevelGroup.getNode();
        frontLevelGroup.on('mouseenter', () => {
            this.stage.container().style.cursor = 'pointer';
        });
        frontLevelGroup.on('mouseleave', () => {
            this.stage.container().style.cursor = 'default';
        });
        sideLevelGroup.on('mouseenter', () => {
            this.stage2.container().style.cursor = 'pointer';
        });
        sideLevelGroup.on('mouseleave', () => {
            this.stage2.container().style.cursor = 'default';
        });
        this.frontImageObj.onload = function(e){  
            // that.resizeImg(this);
            that.xRay.rotation(that.base[0].angle)
            that.heightIn = that.xRay.height();
            that.widthIn = that.xRay.width();
            that.layer.draw();
        }
        this.sideImageObj.onload = function(e){  
            // that.resizeImg(this);
            that.unifyImg(this);
            that.xRayS.rotation(that.base[1].angle)
            that.heightIn2 =that.xRayS.height();
            that.widthIn2 =that.xRayS.width();
            that.layer2.draw();
        }
    },
    methods:{
        //正位患侧信息修改
        frontSlideChange(){
            this.frontSlide  = this.frontSlide == '0' ? '1' : '0';
            this.saveSlide();
        },
        //侧位患侧信息修改
        sideSlideChange(){
            this.sideSlide  = this.sideSlide == '0' ? '1' : '0';
            this.saveSlide();
        },
        //保存患侧信息
        saveSlide(){
            this.$api.saveLeftRight({
                id: this.id,
                before_after: 0,
                left_right: JSON.stringify([{slide: this.frontSlide}, {slide: this.sideSlide}])
            })
        },
        //画布放大
        onIncrease(){ 
            if(this.isConfirm) return false;
            this.multiple = this.multiple / 0.9;
            this.zoom();
        },
        
        //恢复原尺寸
        onReset(type = 0){ 
            if(this.isConfirm) return false;
            this.multiple = 1;
            this.zoom();
        },
        //画布缩小
        onReduce(type = 0){ 
            if(this.isConfirm) return false;
            // if(this.multiple - 0.2 <= 0) return false;
            this.multiple = this.multiple * 0.9;
            this.zoom();
        },
        zoom(){
            this.stage.width( this.widthIn * this.multiple)
            this.stage.height( this.heightIn * this.multiple)
            this.stage.scale({x: this.multiple, y: this.multiple})
            this.stage2.width( this.widthIn2 * this.multiple)
            this.stage2.height( this.heightIn2 * this.multiple)
            this.stage2.scale({x: this.multiple, y: this.multiple})
            this.layer.draw();
            this.layer2.draw();
        },
        resizeImg(image){
            let w = image.width; //图片宽度
            let h = image.height; //图片宽度
            let FitWidth = this.configKonva.width //画布宽度
            let FitHeight = this.configKonva.height //画布宽度
            // 当图片比预览区域小时不做任何改变
            if(w < FitWidth && h < FitHeight){
                return false;
            };

            // 当实际图片比例大于预览区域宽高比例时
            if(image.width / image.height >= FitWidth / FitHeight){
                //宽度超出 
                image.height = ((image.height * FitWidth) / image.width);
                image.width = FitWidth; 
            }else{ 
                //高度超出
                image.width = ((image.width * FitHeight) / image.height);
                image.height = FitHeight;
            }
        },
        unifyImg(image){
            let proportion1 = this.base[0].proportion;
            let proportion2 = this.base[1].proportion;
            let unify = proportion1 / proportion2;
            image.height = image.height * unify;
            image.width = image.width * unify;
            this.isUnify = true;
            return image;
        },
        //提交，下一步
        async submit(){
            if(this.disabled) return false;
            this.disabled = true;
            if(!this.isConfirm){
                this.disabled = false;
                return this.$message.error(this.$t('report.message4'));
            }
            this.$api.savePoint({
                id: this.id,
                obj: JSON.stringify(this.levelArray),
                move: this.moveY ? this.moveY : 0,
                zoom: this.multiple,
                before_after: 0,
            }).then(res => {
                //比例测量
                if(res.code){
                    return this.$router.push('/pre/measure?id=' + this.id);
                }
                this.disabled = false;
                this.$message.error(res.msg);
            })
           
        },
        //水平点确认
        lineComfirm(){
            if(this.isConfirm) return false;
            this.isConfirm = true;
            let frontLevelGroup = this.$refs.frontLevelGroup.getNode();
            let sideLevelGroup = this.$refs.sideLevelGroup.getNode();
            let frontY = frontLevelGroup.y();
            let sideY = sideLevelGroup.y();
            this.levelArray = [
                {
                    x: frontLevelGroup.x(),
                    y: frontLevelGroup.y()
                },
                {
                    x: sideLevelGroup.x(),
                    y: sideLevelGroup.y()
                }
            ]
            let moveY = frontY - sideY;
            this.moveY = moveY;
            sideLevelGroup.y(frontY);
            frontLevelGroup.draggable(false);
            sideLevelGroup.draggable(false);
            this.xRayS.y(this.xRayS.y() + moveY)
            this.layer.draw();
            this.layer2.draw();
        },
        //水平还原
        lineRevocation(){
            if(!this.isConfirm) return false;
            this.isConfirm = false;
            let frontLevelGroup = this.$refs.frontLevelGroup.getNode();
            let sideLevelGroup = this.$refs.sideLevelGroup.getNode();
            frontLevelGroup.y(0);
            sideLevelGroup.y(0);
            this.levelArray = [];
            frontLevelGroup.draggable(true);
            sideLevelGroup.draggable(true);
            this.xRay.y(this.heightIn / 2);
            this.xRayS.y(this.heightIn / 2);
            this.layer.draw();
            this.layer2.draw();
           
        },
        //退出登录
        logout(){
            this.$confirm('是否退出登录？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.logout().then(res => {
                    if (res.code) {
                        return	this.$router.push('/login')
                    }
                    this.$message.error(res.msg);
                })
                .catch(err => {
                    // this.$message.error('系统繁忙');
                })
            })
        },
        //返回上一步
        lastStep(){
            this.$router.push('/pre/scale?id=' + this.id);
        }
    }
};
</script>
<style scoped>

.warp{
    width: 1440px;
}
.el-main{
    padding: 0 20px;
    background: #f1f5f7;
}
.head {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-header {
  background: #ffffff;
  text-align: right;
}
.btn-logout {
  display: inline-block;
  padding: 8px 17px;
  padding-left: 37px;
  background: #f1f5f7;
  border: 1px solid #364284;
  border-radius: 14px;
  color: #364284;
  margin-right: 50px;
  position: relative;
}
.btn-logout::before {
  content: "";
  background: url(../../assets/img/off.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translate(0%, -50%);
}
.btn-logout:hover {
  cursor: pointer;
}


.text-upload{
    margin-top: 17px;
    text-align: center;
    color: #7ECDF6;
    text-decoration: underline;
    cursor: pointer;
}
.main{
    /* height: ; */
    display: flex;
    justify-content: space-between;
}
.main .canvas{
    width: 76.88%;
    /* height: 66.94%; */
    height: 540px;
    border-radius: 4px;
    background: #000000;
  
}
.main .right-side, .main .left-side{
    width: 15%;
    height: 540px;
    background: #ffffff;
    border-radius: 4px;
}
.main .left-side{
    width: 13%;
}
.main .form{
    padding-top: 50px;
    font-size: 13px;
    font-weight: 400;
    color: #000000;
}
.container{
    background: #000000;
    position: relative;
    margin: 0 5px;
    
}
.container .zoom{
    position: absolute;
    right: 30px;
    bottom: 26px;
    z-index: 99;
}
/* 
.main .btn-submit{
    position: absolute;
    bottom: 25px;
    left:50%;
    transform: translate(-50%,0);
} */
.step-list .item{
    background: #F1F5F7;
    border: 1px solid #364284;
    border-radius: 4px;
    color: #364284;
    margin-top: 14px;
    padding: 12px 22px;
}
.step-list .active{
    background: #364284;
    color: #FFFFFF;
}
.btn-submit, .btn-comfirm, .btn-revocation, .btn-is-comfirm{
    width: 198px;
    height: 38px;
    line-height: 38px;
    border-radius: 4px;
    font-size: 14px;
    margin: 0 auto;
    color: #FFFFFF;
    background: #364284;
	border: 1px solid #364284;
	text-align: center;
    cursor: pointer;
}
.btn-is-comfirm{
    background: #F1F5F7;
    border: 1px solid #364284;
    color: #364284;
}
.btn-revocation{
   background: #F1F5F7;
    border: 1px solid #364284;
    color: #364284;
    margin-top: 10px;
    margin-bottom: 50px;
}
.zoom{
    position: absolute;
    right: 30px;
    bottom: 26px;
    z-index: 99;
}
</style>
